import React, { CSSProperties, Key, useEffect, useRef, useState } from 'react';
import { Spin, Tree } from 'antd';
import { DataNode } from 'antd/lib/tree';
import classNames from 'classnames';
import {
  CircuitProps,
  CircuitType,
  CircuitTypeFormat,
  CircuitUpperLower,
  getCircuitList,
  getCircuitTree2,
} from '../../../../api/circuit';
import { CircuitTreeData2 } from '../../../../api/const';
import circuitIcon from '../../../../assets/images/circuit.svg';
import styles from './index.module.scss';
import { useLocation } from 'react-router-dom';
import { useUpdateEffect } from 'ahooks';
import { Input, Select, Tag, Tooltip } from '@maxtropy/components';

const { Search } = Input;
const { Option } = Select;

export interface CircuitTreeLeafInfo {
  capacity?: number | null;
  circuitName: string;
  circuitId: number;
  cabinetId: number;
  measurementId: CircuitType;
  hasLineLoss: boolean;
}
export interface CircuitTreeLeafInfo2 {
  voltageLevel?: CircuitUpperLower;
  capacity?: number | null;
  name: string;
  circuitId: number;
  type: CircuitType;
  hasLineLoss: boolean;
  hasOu: boolean;
  distributionCabinetId?: number;
  distributionCabinetName?: string;
  voltageLevelIn?: CircuitUpperLower;
}

export interface CircuitTreeProps {
  className?: string;
  style?: CSSProperties;
  changeTreeSelect?: (value: React.Key[], info: CircuitTreeLeafInfo2) => void;
}

// 一级节点及其子节点是否包含搜索关键字
const wholeNodeContainFilterTxt = (node: CircuitTreeData2, filterTxt?: string): boolean => {
  if (node.name?.includes(filterTxt || '')) return true;
  // 如果当前节点有子节点，则遍历子节点
  if (node.children && node.children.length) {
    for (let child of node.children) {
      if (wholeNodeContainFilterTxt(child, filterTxt)) {
        return true;
      }
    }
  }
  return false;
};

// 筛选树节点，只保留包含搜索关键字的节点
const filterTree = (tree: CircuitTreeData2[], filterText?: string): CircuitTreeData2[] => {
  if (!filterText) return tree;
  return tree.filter(node => wholeNodeContainFilterTxt(node, filterText));
};

const flattenMap = (data: DataNode[], map: Map<Key, DataNode & { info?: any }> = new Map()) => {
  data.forEach(i => {
    map.set(i.key, i);
    if (i.children) {
      flattenMap(i.children, map);
    }
  });
  return map;
};

const transformTree = (tree?: CircuitTreeData2[], filterTxt?: string): DataNode[] => {
  if (!tree) return [];
  return tree.map(item => {
    filterTxt = filterTxt || '';
    const str = item.name;
    const index = str.indexOf(filterTxt);
    const beforeStr = str.substring(0, index);
    const afterStr = str.slice(index + filterTxt.length);
    return {
      icon: <img src={circuitIcon} alt="" />,
      className: 'circuit-title',
      key: item.id,
      title: item.name ? (
        <Tooltip mouseEnterDelay={2} title={`${item.name}【${CircuitTypeFormat[item.type]}】`} placement="topLeft">
          <span style={{ marginRight: '5px' }} id={`${item.id}`}>
            {index > -1 ? (
              <>
                {beforeStr}
                <span className={styles.filterTxt}>{filterTxt}</span>
                {afterStr}
              </>
            ) : (
              <span>{str}</span>
            )}
          </span>
          <Tag color={'blue'}>{CircuitTypeFormat[item.type]}</Tag>
        </Tooltip>
      ) : (
        '---'
      ),
      selectable: true,
      info: {
        capacity: item.capacity,
        name: item.name,
        circuitId: item.id,
        hasLineLoss: item.hasLineLoss,
        type: item.type,
        cabinetId: item.cabinetId,
        distributionCabinetId: item.cabinetId,
        hasOu: item.hasOu,
        voltageLevel: item.voltageLevel,
        voltageLevelIn: item.voltageLevelIn,
      },
      children: item.children ? transformTree(item.children, filterTxt) : [],
    };
  });
};

const searchTreeData = (data: CircuitProps[], filterTxt?: string) => {
  let treeData: any = [];
  data.forEach(item => {
    filterTxt = filterTxt || '';
    if (!item.name.includes(filterTxt)) return;
    const str = item.name;
    const index = str.indexOf(filterTxt);
    const beforeStr = str.substring(0, index);
    const afterStr = str.slice(index + filterTxt.length);
    treeData.push({
      icon: <img src={circuitIcon} alt="" />,
      className: 'circuit-title',
      key: item.id,
      title: item.name ? (
        <Tooltip mouseEnterDelay={2} title={`${item.name}【${CircuitTypeFormat[item.type]}】`} placement="topLeft">
          <span style={{ marginRight: '5px' }} id={`${item.id}`}>
            {index > -1 ? (
              <>
                {beforeStr}
                <span className={styles.filterTxt}>{filterTxt}</span>
                {afterStr}
              </>
            ) : (
              <span>{str}</span>
            )}
          </span>
          <Tag color={'blue'}>{CircuitTypeFormat[item.type]}</Tag>
        </Tooltip>
      ) : (
        '---'
      ),
      selectable: true,
      info: {
        capacity: item.capacity,
        name: item.name,
        circuitId: item.id,
        hasLineLoss: item.hasLineLoss,
        type: item.type,
        hasOu: true,
        voltageLevel: item.voltageLevel,
        voltageLevelIn: item.voltageLevelIn,
        distributionCabinetId: item.distributionCabinetId,
        distributionCabinetName: item.distributionCabinetName,
      },
    });
  });
  return treeData;
};

const CircuitTree: React.FC<CircuitTreeProps> = props => {
  const { className, changeTreeSelect, style } = props;

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const url_circuitPhysicalQuantityName = urlSearchParams.get('circuitPhysicalQuantityName') || undefined;
  const hasId = urlSearchParams.get('id');

  const [data, setData] = useState<DataNode[]>();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useState<any>({});
  const [filterTxt, setFilterTxt] = useState<string>('');
  // 没有选择类型的树返回
  const [circuitTreeRes, setCircuitTreeRes] = useState<CircuitTreeData2[]>(); // 有类型的数据
  // 选择了类型的列表返回
  const [circuitListRes, setCircuitListRes] = useState<CircuitProps[]>(); // 没有类型的数据

  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);

  // 是否第一次获取树数据
  const [flag, setFlag] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    // 如果没有选择类型，则是树；否则是平铺列表
    if (!searchParams.type) {
      getCircuitTree2()
        .then(res => {
          if (res && res.list) {
            setCircuitTreeRes(res.list ?? []);
            // 如果是第一次获取数据，用路由里的参数筛选树；否则用输入的回路名称筛选树
            const realFilterTxt = flag ? url_circuitPhysicalQuantityName : filterTxt;
            const filterData = filterTree(res.list, realFilterTxt);
            const treeData = transformTree(filterData, realFilterTxt);
            // 对treeData进行平铺，为了展开key
            const flattenTreeData = flattenMap(treeData);
            // 如果首次进入页面获取数据，展开所有节点；否则展开筛选后的节点
            if (flag) {
              setExpandedKeys(Array.from(flattenTreeData.keys()) as string[]);
            } else {
              let newExpandedKeys: string[] = [];
              flattenTreeData.forEach((value, key) => {
                if (value.info?.name?.includes(filterTxt)) {
                  newExpandedKeys.push(key as string);
                }
              });
              setExpandedKeys(newExpandedKeys);
            }
            setData(treeData);
            if (hasId && flag) {
              // 有传参默认选择传参的id
              setSelectedKeys([Number(hasId)]);
              changeTreeSelect?.([Number(hasId)], flattenTreeData.get(Number(hasId))?.info);
            } else {
              // 没有传参默认选择第一条
              if (!treeData[0]) return;
              setSelectedKeys([Number(treeData[0].key)]);
              changeTreeSelect?.([Number(treeData[0].key)], flattenTreeData.get(Number(treeData[0].key))?.info);
            }
            setFlag(false);
          }
        })
        .finally(() => setLoading(false));
    } else {
      getCircuitList({ ...searchParams })
        .then(res => {
          setCircuitListRes(res);
          const treeData = searchTreeData(res, filterTxt);
          const flattenTreeData = flattenMap(treeData);
          setExpandedKeys(Array.from(flattenTreeData.keys()) as string[]);
          setData(treeData);
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, hasId]);

  // 根据输入的回路名称筛选树
  useEffect(() => {
    setAutoExpandParent(true);
    if (!data) return;
    // 如果没有类型搜索
    if (!searchParams.type) {
      const filterData = filterTree(circuitTreeRes || [], filterTxt);
      const treeData = transformTree(filterData, filterTxt);
      const flattenTreeData = flattenMap(treeData);
      let newExpandedKeys: string[] = [];
      flattenTreeData.forEach((value, key) => {
        if (value.info?.name?.includes(filterTxt)) {
          newExpandedKeys.push(key as string);
        }
      });
      setData(treeData);
      setExpandedKeys(newExpandedKeys);
    } else {
      // 如果有类型搜索，是平铺的，所以不需要设置expandedKeys，只需要高亮字
      const treeData = searchTreeData(circuitListRes || [], filterTxt);
      setData(treeData);
    }
  }, [filterTxt]);

  // 回路名称搜索, 滚动到可视区域
  let timer: any = useRef<any>();
  useUpdateEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    timer.current = setTimeout(() => {
      const dom = document.querySelector(`.${styles.filterTxt}`);
      let parentDom = dom?.parentNode as HTMLDivElement;
      parentDom?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 300);
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
    };
  }, [filterTxt]);

  const onSelect = (selectedKeys: React.Key[], e: any) => {
    changeTreeSelect?.(selectedKeys, e.node.info);
    setSelectedKeys(selectedKeys);
  };

  // 让锚点平滑滚到可视区域中间位置
  useEffect(() => {
    if (hasId && data && data.length > 0) {
      let anchor = document.getElementById(hasId);
      anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [data, hasId, selectedKeys]);

  return (
    <div className={classNames(className, styles.treeArea)} style={style}>
      <div className={styles.topBox}>
        <div className={styles.filterArea}>
          <div style={{ width: '44px' }}>类型：</div>
          <Select
            placeholder="请选择"
            className={styles.label}
            onChange={(v: CircuitType) => setSearchParams({ ...searchParams, type: v })}
            allowClear
          >
            <Option value="">全部</Option>
            <Option value={CircuitType.GRID_BILLING_CIRCUIT}>
              {CircuitTypeFormat[CircuitType.GRID_BILLING_CIRCUIT]}
            </Option>
            <Option value={CircuitType.INCOMING_CIRCUIT}>{CircuitTypeFormat[CircuitType.INCOMING_CIRCUIT]}</Option>
            <Option value={CircuitType.OUTGOING_CIRCUIT}>{CircuitTypeFormat[CircuitType.OUTGOING_CIRCUIT]}</Option>
            <Option value={CircuitType.BUS_COUPLER_CIRCUIT}>
              {CircuitTypeFormat[CircuitType.BUS_COUPLER_CIRCUIT]}
            </Option>
            <Option value={CircuitType.TRANSFORMER_CIRCUIT}>
              {CircuitTypeFormat[CircuitType.TRANSFORMER_CIRCUIT]}
            </Option>
          </Select>
        </div>
        <div className={styles.searchArea}>
          <Search
            placeholder="请输入回路名称"
            allowClear
            onChange={e => {
              setFilterTxt(e.target.value);
            }}
          />
        </div>
      </div>

      <div className={styles.treeBox}>
        <Spin spinning={loading}>
          {data && data.length > 0 ? (
            <Tree
              className={styles.treeStyle}
              showIcon
              blockNode
              treeData={data}
              autoExpandParent={autoExpandParent}
              expandedKeys={expandedKeys}
              // defaultSelectedKeys={[Number(hasId)]}
              selectedKeys={selectedKeys}
              onExpand={expandedKeys => {
                setExpandedKeys(expandedKeys as string[]);
                setAutoExpandParent(false);
              }}
              onSelect={onSelect}
            />
          ) : (
            <div />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default CircuitTree;
