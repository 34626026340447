import { StatisticsPartition } from '@/pages/EnergyAssetProjectIncome/components/DateSwitch';
import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { Key } from 'react';

export interface OrgOuTreeReq {
  ouName?: string;
}

export interface OuList {
  id: number;
  name: string;
  serialNumber: string;
}

export interface OrgOuTreeRes {
  customerName: string;
  mcid: number;
  ouList: OuList[];
}

export interface OuIncomeInfoReq {
  ouId: Key; //ouId
  resolution: StatisticsPartition; //时间颗粒度。支持：D(日),M(月)
  startTime: Key; //开始时间
  endTime: Key; //结束时间
}

export interface OuIncomeInfoRes {
  projectName: string;
  directorName: string;
  projectAddress: string;
  projectTotalIncome: number;
  averageIncomeByDay: number;
  highestIncomeByDay: number;
  lowestIncomeByDay: number;
}

// ou-收益 相关信息
export function getOuIncomeInfo(params: OuIncomeInfoReq) {
  return fetch<OuIncomeInfoRes>(
    `/api/v2/energy-asset-project/ou-income-info`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface PvIncome {
  income: number;
  incomeRate: number;
}

export interface BsaIncome {
  income: number;
  incomeRate: number;
}

export interface EvIncome {
  income: number;
  incomeRate: number;
}

export interface ProjectTotalIncomeRes {
  incomeDate: number;
  incomeCount: number;
  pvIncome: PvIncome;
  bsaIncome: BsaIncome;
  evIncome: EvIncome;
}

// 项目总收益
export function getProjectTotalIncome(params: OuIncomeInfoReq) {
  return fetch<{ list: ProjectTotalIncomeRes[] }>(
    `/api/v2/energy-asset-project/project-total-income`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface ProjectIncomeProportionRes {
  id: number;
  stationName: string;
  type: string;
  cumulativeGain: number;
  revenueProportion: number;
}

// 收益占比饼图
export function getProjectIncomeProportion(params: OuIncomeInfoReq) {
  return fetch<{ list: ProjectIncomeProportionRes[] }>(
    `/api/v2/energy-asset-project/income-proportion?`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}
