import {
  EllipsisSpan,
  Paging,
  Table,
  useBreadcrumbRoutes,
  usePaging,
  Checkbox,
  Form,
  Input,
  Select,
  Tabs,
  Button,
  Wrapper,
  CustomFilter,
  Modal,
} from '@maxtropy/components';
import { Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ColumnType } from 'antd/es/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  fetchStrategyTemplatePage,
  fetchVersionList,
  StrategyTemplatePageQuery,
  updateStrategyTemplateStatus,
  getBsaListBySystemVersion,
} from '@/api/strategy';
import { PermissionsType } from '../../../common/permissionsConst';
import { useHasPermission } from '@/utils/hooks';
import { StrategyTemplate, StrategyTemplateStatus } from '../types';
import SelectVersionModal from './SelectVersionModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { BsaListProps } from '@/api/bsa';
import dayjs from 'dayjs';
import PutRecordTemplateModal from './PutRecordTemplateModal';
import { FormStatus } from '@/types';
import styles from './index.module.scss';
import { PlusOutlined } from '@ant-design/icons';

export interface StrategyTemplateListProps {}
const tabItems = [
  { label: '策略模板', key: '1' },
  { label: '就地策略日历', key: '2' },
];
const StrategyTemplateList: React.FC<StrategyTemplateListProps> = () => {
  const routesContext = useBreadcrumbRoutes();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const bsaId = urlSearchParams.get('bsaId') || undefined;
  const queryClient = useQueryClient();
  const [form] = useForm();
  const [searchParams, setSearchParams] = useState<StrategyTemplatePageQuery>();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [currentTableKey, setCurrentTablekey] = useState<string>();

  const { data, isLoading, refetch } = useQuery(
    ['strategyTemplatePage', searchParams, currentTableKey, pageSize, pageOffset],
    () =>
      fetchStrategyTemplatePage({
        page: pageOffset,
        size: pageSize,
        ...searchParams,
        versionId: Number(currentTableKey),
      }),
    {
      enabled: !!currentTableKey,
    }
  );
  const { data: versions } = useQuery('versionList', fetchVersionList);
  const [showSelectVersionModal, setShowSelectVersionModal] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);

  // 投放记录弹框
  const [putRecordVisible, setPutRecordVisible] = useState(false);
  const navigate = useNavigate();
  const [currentRow, setCurrentRow] = useState<StrategyTemplate>();
  useEffect(() => {
    if (data) {
      setTotalCount(data.total);
    }
  }, [data, setTotalCount]);

  const hasCreatePermission = useHasPermission(PermissionsType.B_CREATE_STRATEGY_TEMPLATE);
  const hasEditPermission = useHasPermission(PermissionsType.B_UPDATE_STRATEGY_TEMPLATE);
  const hasPutRecordPers = useHasPermission(PermissionsType.B_STRATEGY_DELIVER_LOG);
  const hasCopyPers = useHasPermission(PermissionsType.B_STRATEGY_COPY);
  const updateStatus = (id: number, status: StrategyTemplateStatus) => {
    Modal.confirm({
      title: `是否确认${StrategyTemplateStatus.MAP[status]}模板？`,
      content: null,
      okText: '继续',
      cancelText: '取消',
      onOk: () => {
        updateStrategyTemplateStatus(id, status).then(() => {
          queryClient.invalidateQueries('strategyTemplatePage');
        });
      },
    });
  };
  const [bsaList, setBsaList] = useState<BsaListProps[]>([]);
  // 查询所属储能站
  useEffect(() => {
    getBsaListBySystemVersion().then(setBsaList);
  }, []);

  useEffect(() => {
    if (bsaId && bsaList) {
      setSearchParams({
        ...searchParams,
        bsaId: Number(bsaId),
      });
    }
  }, [bsaId, bsaList]);

  const actionColumn: ColumnType<StrategyTemplate> = {
    title: '操作',
    dataIndex: 'action',
    width: 300,
    fixed: 'right' as const,
    render: (_, record) => {
      // 状态: 0 禁用; 1 启用; 2 作废;
      let invalid = record.status === StrategyTemplateStatus.INVALID;
      let disable = record.status === StrategyTemplateStatus.DISABLED;
      let enable = record.status === StrategyTemplateStatus.ENABLED;
      return (
        <Space size={16}>
          {hasEditPermission && (
            <Button
              disabled={invalid}
              type="link"
              onClick={() =>
                window.open(`/dmes/energy-storage/strategy/${record.id}/edit?bsaId=${record.bsaId}`, '__blank')
              }
            >
              编辑
            </Button>
          )}
          <Button
            disabled={invalid || disable}
            type="link"
            onClick={() => window.open(`/dmes/energy-storage/strategy/${record.id}/launch/${record.bsaId}`, '__blank')}
          >
            投放
          </Button>

          <Button
            type="link"
            disabled={invalid}
            onClick={() => {
              updateStatus(record.id, StrategyTemplateStatus.INVALID);
            }}
          >
            作废
          </Button>

          {enable && (
            <Button
              type="link"
              disabled={invalid}
              onClick={() => {
                updateStatus(record.id, StrategyTemplateStatus.DISABLED);
              }}
            >
              停用
            </Button>
          )}

          {disable && (
            <Button
              type="link"
              onClick={() => {
                updateStatus(record.id, StrategyTemplateStatus.ENABLED);
              }}
            >
              启用
            </Button>
          )}

          {hasCopyPers && (
            <Button
              type="link"
              onClick={() => {
                setCurrentRow({ ...record, sysVersionId: currentTableKey ?? '' });
                setShowCopyModal(true);
              }}
            >
              复制
            </Button>
          )}

          {hasPutRecordPers && (
            <Button
              type="link"
              onClick={() => {
                setCurrentRow(record);
                setPutRecordVisible(true);
              }}
            >
              投放记录
            </Button>
          )}
        </Space>
      );
    },
  };
  const tableTabItem = useMemo(() => {
    return (versions ?? []).map(item => ({
      label: item.version,
      key: item.id.toString(),
      children: (
        <Table
          loading={isLoading}
          sticky
          rowKey="id"
          scroll={{ x: 1500 }}
          columns={[...columns, actionColumn]}
          dataSource={data?.list}
        />
      ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versions, data]);

  // 设置初始默认key
  useEffect(() => {
    if (versions && versions.length > 0) {
      setCurrentTablekey(`${versions[0].id}`);
    }
  }, [versions]);
  const currentTabName = useMemo(() => {
    return versions?.find(item => item.id === Number(currentTableKey))?.name;
  }, [currentTableKey, versions]);
  return (
    <>
      {showCopyModal && (
        <SelectVersionModal
          onCancel={() => setShowCopyModal(false)}
          status={FormStatus.Copy}
          row={currentRow}
          onOK={(_versionId, _versionName, bsaId, name) =>
            navigate(`/dmes/energy-storage/strategy/${currentRow?.id}/copy?bsaId=${bsaId}&bsaName=${name}`)
          }
        />
      )}
      {showSelectVersionModal && (
        <SelectVersionModal
          status={FormStatus.Create}
          onCancel={() => setShowSelectVersionModal(false)}
          onOK={(versionId, versionName, bsaId, name) =>
            navigate(
              `/dmes/energy-storage/strategy/new?versionId=${versionId}&versionName=${versionName}&bsaId=${bsaId}&bsaName=${name}`
            )
          }
        />
      )}
      {putRecordVisible && (
        <PutRecordTemplateModal visible={putRecordVisible} row={currentRow} close={() => setPutRecordVisible(false)} />
      )}
      <Wrapper routes={routesContext?.routes} className={styles.wrapperTabsPadding}>
        <Tabs
          activeKey="1"
          onChange={v => navigate(`/dmes/energy-storage/strategy${v === '2' ? '/calendar' : ''}`)}
          items={tabItems}
        ></Tabs>
        <CustomFilter
          form={form}
          onFinish={(values: StrategyTemplatePageQuery) => {
            setSearchParams({ ...values });
            setPageOffset(1);
            refetch();
          }}
          onReset={() => {
            setSearchParams(undefined);
            setPageOffset(1);
          }}
        >
          <Form.Item name="code" label="模版编号：">
            <Input placeholder="请输入编号查询" />
          </Form.Item>

          <Form.Item name="name" label="模版名称：">
            <Input placeholder="请输入模板名称查询" />
          </Form.Item>

          <Form.Item name="status" label="状态：">
            <Select style={{ width: '100%' }} placeholder="请选择">
              {Object.keys(StrategyTemplateStatus.MAP).map(key => {
                let status = +key as StrategyTemplateStatus;
                return (
                  <Select.Option key={key} value={key}>
                    {StrategyTemplateStatus.MAP[status]}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="bsaId" label="所属储能站：" initialValue={bsaId ? Number(bsaId) : undefined}>
            <Select style={{ width: '100%' }} placeholder="请选择">
              {bsaList?.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="ifCancel" valuePropName="checked">
            <Checkbox>显示已作废模板</Checkbox>
          </Form.Item>
        </CustomFilter>

        {hasCreatePermission && (
          <Space size={10}>
            <Button type="primary" onClick={() => setShowSelectVersionModal(true)}>
              <PlusOutlined /> 新建策略模板
            </Button>
          </Space>
        )}
        <div className={styles.tab_box}>
          <Tabs
            activeKey={currentTableKey}
            tabBarExtraContent={<div className={styles.tab_name}>{currentTabName}</div>}
            onChange={setCurrentTablekey}
            items={tableTabItem}
          />
        </div>
        <Paging pagingInfo={pagingInfo} />
      </Wrapper>
    </>
  );
};

const columns: ColumnType<StrategyTemplate>[] = [
  {
    title: '模版编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '模版名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: StrategyTemplateStatus) => <EllipsisSpan value={StrategyTemplateStatus.MAP[v]} />,
  },
  {
    title: '所属储能站',
    dataIndex: 'bsaName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'lastUpdateUser',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export default StrategyTemplateList;
